import { Alert, AlertDescription, AlertTitle, Button, CloseButton, Link } from '@chakra-ui/react'
import useUser from 'hooks/useUser'
import React from 'react'
import { mutate } from 'swr'
import createPersistedState from 'use-persisted-state'
import { useFetchWithToken } from 'utils/backendDataFetcher'
import l10n from '../l10n'

const useDismissedState = createPersistedState('verifyAlertIsDismissed')

const AUTHORIZE = `
  mutation authorize($input: AuthorizationInput!) {
    authorize(input: $input) {
      redirect_url
    }
  }
`

const VerifyAlert = ({ dismissable = true }: Props) => {
  const { data, error } = useUser()
  const { fetcher } = useFetchWithToken()
  const [isDismissed, setIsDismissed] = useDismissedState(false)

  const authorize = async () => {
    try {
      const data = await mutate(AUTHORIZE, async () => {
        return await fetcher.request(AUTHORIZE, {
          input: {
            success_url: `${process.env.NEXT_PUBLIC_APP_ROOT_URL}/minside/profil`,
            failure_url: `${process.env.NEXT_PUBLIC_APP_ROOT_URL}/failed`,
          },
        })
      })
      window.location.href = data.authorize.redirect_url
    } catch (error) {
      console.error(error)
    }
  }

  if (error)
    return (
      <Alert
        backgroundColor="red.50"
        color="red.900"
        flexDirection="column"
        alignItems="start"
        textAlign="left"
        rounded="md"
        status="error"
      >
        <AlertTitle>{l10n.errorSomethingWrong}</AlertTitle>
        <AlertDescription>{error.toString()}</AlertDescription>
      </Alert>
    )
  if (!data) return <></>
  if (data?.user?.memberships.length !== 0) return <></>
  if (isDismissed && dismissable) return <></>

  return (
    <Alert
      status="info"
      backgroundColor="red.50"
      color="red.900"
      flexDirection="column"
      alignItems="start"
      textAlign="left"
      rounded="md"
      my={4}
    >
      <AlertTitle mb={1}>{l10n.verifyAlertTitle}</AlertTitle>
      <AlertDescription lineHeight="base">{l10n.verifyAlertDescription1}.</AlertDescription>
      <AlertDescription lineHeight="base" mt={2}>
        {l10n.verifyAlertDescription2}.{' '}
        <Link textDecoration="underline" href="https://www.feide.no/">
          {l10n.moreInfoFeide}.
        </Link>
      </AlertDescription>
      {dismissable && (
        <CloseButton
          onClick={() => setIsDismissed(true)}
          size="sm"
          color="red.900"
          position="absolute"
          right="8px"
          top="8px"
        />
      )}
      <Button mt={4} size="sm" colorScheme="red" onClick={authorize}>
        {l10n.logInWithFeide}
      </Button>
    </Alert>
  )
}

type Props = {
  dismissable?: boolean
}

export default VerifyAlert
