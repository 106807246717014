import { GridItem as CGridItem, GridItemProps } from '@chakra-ui/layout'

const gridItemsProps = {
  colSpan: { base: 12, lg: 8, xl: 6 },
  colStart: { lg: 3, xl: 4 },
}

export default function GridItem({ children, ...rest }: GridItemProps) {
  return (
    <CGridItem {...gridItemsProps} {...rest}>
      {children}
    </CGridItem>
  )
}
