import * as React from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/nb'
import capitalize from '../utils/capitalize'
import { useRouter } from 'next/router'

dayjs.extend(relativeTime)

const Date = ({ children, relative, withTime, title, formatString }: Props) => {
  const { locale } = useRouter()
  const dayjsLocale = locale.substring(0, 2)

  if (!children) return <></>
  let date: string | number
  // Dates from 3rd parties might not be timestamps
  if (children.includes('.') || children.includes(':') || children.includes('-')) {
    date = children
  } else {
    date = Number(children)
  }
  if (relative)
    return <span suppressHydrationWarning={true}>{dayjs(date).locale(dayjsLocale).fromNow()}</span>
  if (withTime)
    return (
      <span suppressHydrationWarning={true}>
        {dayjs(date).locale(dayjsLocale).format('DD.MM.YYYY – HH.mm')}
      </span>
    )
  if (title)
    return (
      <span suppressHydrationWarning={true}>
        {capitalize(dayjs(date).locale(dayjsLocale).format('MMMM YYYY'))}
      </span>
    )
  if (formatString)
    return (
      <span suppressHydrationWarning={true}>
        {capitalize(dayjs(date).locale(dayjsLocale).format(formatString))}
      </span>
    )
  return (
    <span suppressHydrationWarning={true}>
      {dayjs(date).locale(dayjsLocale).format('DD.MM.YYYY')}
    </span>
  )
}

type Props = {
  children: string
  relative?: boolean
  withTime?: boolean
  title?: boolean
  formatString?: string
}

export default Date
