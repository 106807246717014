import { Box, Divider, Flex, Icon, Link, Spinner, Text } from '@chakra-ui/react'
import GridContainer from '../components/GridContainer'
import GridItem from '../components/GridItem'
import RichText from '../components/RichText'
import useLocalisedUri from '../hooks/useLocalisedUri'
import l10n from '../l10n'
import { ArrowUpRight } from 'lucide-react'
import RouterLink from 'next/link'
import { useRouter } from 'next/router'
import { MenuItems as MenuItemsType } from '../types'

export const FlagNorwegian = () => (
  <Box width={4} height={4} mr={2}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <circle cx="256" cy="256" r="256" fill="#eee" />
      <path
        fill="#d80027"
        d="M8.8 322.8A256.2 256.2 0 0 0 100.2 459V322.8H8.8zm225 188.2a259.3 259.3 0 0 0 22.2 1 256 256 0 0 0 247.2-189.2H233.7V511zm269.4-321.8A256 256 0 0 0 233.7 1v188.2h269.5zM100.2 53A256.2 256.2 0 0 0 8.8 189.2h91.4V53z"
      />
      <path
        fill="#0052b4"
        d="M509.8 222.6H200.3V6.1a254.3 254.3 0 0 0-66.7 25v191.5H2.2a258.2 258.2 0 0 0 0 66.8h131.4v191.5a254.3 254.3 0 0 0 66.7 25V289.4h309.5a258.6 258.6 0 0 0 0-66.8z"
      />
    </svg>
  </Box>
)

export const FlagEnglish = () => (
  <Box width={4} height={4} mr={2}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <circle cx="256" cy="256" r="256" fill="#eee" />
      <path
        fill="#0052b4"
        d="M53 100.1a255 255 0 0 0-44.2 89.1H142l-89-89zm450.2 89.1a255 255 0 0 0-44.1-89l-89 89h133zM8.8 322.8a255 255 0 0 0 44.1 89l89-89H9zm403-269.9a255 255 0 0 0-89-44V142l89-89zM100.2 459.1a255 255 0 0 0 89.1 44V370l-89 89zm89-450.3a255 255 0 0 0-89 44.1l89 89.1V8.8zm133.6 494.4a255 255 0 0 0 89-44.1l-89-89v133zM370 322.8l89 89a255 255 0 0 0 44.2-89H370z"
      />
      <g fill="#d80027">
        <path d="M509.8 222.6H289.4V2.2A258.6 258.6 0 0 0 256 0c-11.3 0-22.5.7-33.4 2.2v220.4H2.2A258.6 258.6 0 0 0 0 256c0 11.3.7 22.5 2.2 33.4h220.4v220.4a258.4 258.4 0 0 0 66.8 0V289.4h220.4A258.5 258.5 0 0 0 512 256c0-11.3-.7-22.5-2.2-33.4z" />
        <path d="M322.8 322.8L437 437a256.6 256.6 0 0 0 15-16.4l-97.7-97.8h-31.5zm-133.6 0L75 437a256.6 256.6 0 0 0 16.4 15l97.8-97.7v-31.5zm0-133.6L75 75a256.6 256.6 0 0 0-15 16.4l97.7 97.8h31.5zm133.6 0L437 75a256.3 256.3 0 0 0-16.4-15l-97.8 97.7v31.5z" />
      </g>
    </svg>
  </Box>
)

const Footer = ({ uid, menuItems, submenuItems, contactInfo }: Props) => {
  const router = useRouter()
  const { data, error, isLoading, localisedUrlNo, localisedUrlEn } = useLocalisedUri()

  return (
    <Box as="footer" key={uid} backgroundColor="white" mt={[16, 20]}>
      <GridContainer>
        <GridItem colSpan={12} colStart={0}>
          <Divider />
        </GridItem>
      </GridContainer>
      <GridContainer rowGap={[8]} mt={[6, 8]}>
        <GridItem colSpan={[12, 12, 6, 3]} colStart={0}>
          <Text fontSize={['md', 'lg']} fontWeight="medium" mb={[2, 4]}>
            {l10n.aboutSamskipnaden}
          </Text>
          <Flex flexDirection={'column'} gap={2}>
            {submenuItems?.map((item) => (
              <RouterLink key={item.uid} href={item.generalLink?.url} passHref legacyBehavior>
                <Link
                  target={item.generalLink?.target}
                  isExternal={item.generalLink?.target === '_blank'}
                >
                  {item.generalLink?.text}{' '}
                  {item.generalLink?.target === '_blank' && (
                    <Icon as={ArrowUpRight} position="relative" top={'3px'} />
                  )}
                </Link>
              </RouterLink>
            ))}
          </Flex>
        </GridItem>
        <GridItem colSpan={[12, 12, 6, 3]} colStart={0}>
          <Text fontSize={['md', 'lg']} fontWeight="medium" mb={[2, 4]}>
            {l10n.usefulLinks}
          </Text>
          <Flex flexDirection={'column'} gap={2}>
            {menuItems?.map((item) => (
              <RouterLink key={item.uid} href={item.generalLink?.url} passHref legacyBehavior>
                <Link
                  target={item.generalLink?.target}
                  isExternal={item.generalLink?.target === '_blank'}
                >
                  {item.generalLink?.text}&nbsp;
                  {item.generalLink?.target === '_blank' && (
                    <Icon as={ArrowUpRight} position="relative" top={'3px'} />
                  )}
                </Link>
              </RouterLink>
            ))}
          </Flex>
        </GridItem>
        <GridItem colSpan={[12, 12, 6, 4]} colStart={null}>
          <Text fontSize={['md', 'lg']} fontWeight="medium">
            {l10n.contact}
          </Text>
          <RichText mt={[2, 4]} disableProse>
            {contactInfo?.richText}
          </RichText>
        </GridItem>
        <GridItem colSpan={[12, 12, 6, 2]} colStart={null}>
          <Text fontSize={['md', 'lg']} fontWeight="medium" mb={[2, 4]}>
            {l10n.language}
          </Text>
          {!isLoading ? (
            <>
              <RouterLink href={`/${localisedUrlNo}`} passHref locale="nb-NO" legacyBehavior>
                <Link display="block">
                  <Flex alignItems="center">
                    <FlagNorwegian />
                    Norsk
                  </Flex>
                </Link>
              </RouterLink>
              <RouterLink href={`/${localisedUrlEn}`} passHref locale="en" legacyBehavior>
                <Link display="block">
                  <Flex alignItems="center">
                    <FlagEnglish />
                    English
                  </Flex>
                </Link>
              </RouterLink>
            </>
          ) : (
            <Spinner size={'sm'} />
          )}
        </GridItem>
      </GridContainer>
      <GridContainer mt={[6, 8]}>
        <GridItem colSpan={12} colStart={null}>
          <Divider></Divider>
          <Flex py={6} justifyContent="space-between" alignItems={'center'} color="gray.600">
            <Box as="img" src="/logos/mobile.svg" alt="" width={12} display="inline-block" mr={1} />
            <Flex gap={1}>
              <RouterLink href={'/om-samskipnaden/personvern'} passHref legacyBehavior>
                <Link textDecoration={`underline`}>{l10n.privacy}</Link>
              </RouterLink>
              {l10n.and}
              <RouterLink href={'/om-samskipnaden/cookies'} passHref legacyBehavior>
                <Link textDecoration={`underline`}>{l10n.cookies}</Link>
              </RouterLink>
            </Flex>
          </Flex>
        </GridItem>
      </GridContainer>
    </Box>
  )
}

type MenuItemsProps = {
  menuItems: [MenuItemsType]
  arrow?: boolean
}

type Props = {
  uid: string
  services?: [
    {
      uid: string
      url: string
      uri: string
      title: string
      slug: string
    }
  ]
  submenuItems?: [MenuItemsType]
  menuItems?: [MenuItemsType]
  contactInfo?: {
    uid: string
    richText: string
  }
}

export default Footer
