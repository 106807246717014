import { filterXSS } from 'xss'
import { Box, chakra, useStyleConfig, type BoxProps } from '@chakra-ui/react'

export type ProseProps = BoxProps

function Prose(props: ProseProps) {
  const { children, as = 'article', ...rest } = props
  const styles = useStyleConfig('Prose', { ...props })

  return (
    <chakra.div as={as} __css={{ ...styles }} {...rest}>
      {children}
    </chakra.div>
  )
}

const RichText = ({ children, disableProse, ...rest }: Props) => {
  if (typeof children !== 'string') return <></>
  if (disableProse)
    return (
      <Box
        maxWidth={'65ch'}
        {...rest}
        dangerouslySetInnerHTML={{
          __html: filterXSS(children),
        }}
      ></Box>
    )

  return (
    <Prose
      maxWidth={'65ch'}
      {...rest}
      dangerouslySetInnerHTML={{
        __html: filterXSS(children),
      }}
    ></Prose>
  )
}

type Props = {
  children: string
  disableProse?: boolean
} & BoxProps

export default RichText
