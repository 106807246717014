import useSWR from 'swr'

export const GET_QR = `
  query getQr {
    user {
      name
      picture_url
      memberships {
        primary_affiliation
      }
    }
    loyaltyPrograms {
      id
      name
      slug
      description
      customer {
        qr_code
      }
    }
  }
`

const useQR = () => {
  return useSWR(GET_QR)
}

export default useQR
