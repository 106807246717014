import { Grid, GridProps } from '@chakra-ui/react'
import * as React from 'react'

const GridContainer = ({ children, ...rest }: GridProps) => {
  return (
    <Grid
      columnGap={[2, 10]}
      templateColumns={['repeat(12, 1fr)']}
      maxWidth={'9xl'}
      mx="auto"
      px={[3, 10]}
      overflowX="hidden"
      {...rest}
    >
      {children}
    </Grid>
  )
}

export default GridContainer
