import useSWR from 'swr'
import constants from '../utils/constants'

export const GET_USER = `
  query getUser {
    user {
      id
      created_at
      sub
      name
      email
      picture_url
      onboarding_completed
      fire_video_shown_at
      memberships {
        organization {
          id
          type
          legal_name
        }
        primary_affiliation
        all_affiliations
      }
    }
    loyaltyPrograms {
      id
      name
      slug
      description
      customer {
        webshop_token
        qr_code
        customer_groups
        last_order_at
        features {
          name
          type
          max_balance
          current_balance
          last_earning
        }
        purchases {
          serial_number
          type
          sales_type
          currency
          total_gross_amount
          total_net_amount
          total_discount_amount
          purchased_at
          pos {
            business_unit
            installation
          }
          products {
            name
            tax_vat_pct
            gross_amount
            net_amount
            discount_amount
            quantity
          }
        }
      }
    }
    favorites {
      id
      type
      name
      uid
      slug
    }
    services {
      id
      type
      consent {
        id
        consented_at
      }
    }
  }
`

const useUser = () => {
  const { data, error, isValidating, mutate } = useSWR(GET_USER)
  const userCampus = data?.favorites?.find((f) => f.type === constants.FavoriteTypes.Campuses)
  const userLoyaltyPrograms = data?.loyaltyPrograms
  const isLoading = !error && !data
  return { data, error, isValidating, isLoading, mutate, userCampus, userLoyaltyPrograms }
}

export default useUser
