import {
  Avatar,
  Button,
  Flex,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import useQR from 'hooks/useQR'
import l10n from 'l10n'
import React, { Fragment } from 'react'
import { RiQrCodeLine } from 'react-icons/ri'
import VerifyAlert from './VerifyAlert'
import { QrCode as QRCodeIcon } from 'lucide-react'

const QrCode = ({}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { data, error } = useQR()

  if (error) {
    return (
      <Button as={Link} href="/minside/logginn" width={['100%', 'auto']} colorScheme="orange">
        {l10n.openQrCode}
      </Button>
    )
  }
  if (!data)
    return (
      <Button
        width="fit-content"
        colorScheme="orange"
        leftIcon={<RiQrCodeLine />}
        onClick={onOpen}
        isLoading
        loadingText="Henter QR-kode..."
      >
        {''}
      </Button>
    )
  if (!!!data?.loyaltyPrograms?.length) return <></>

  return (
    <>
      {data?.loyaltyPrograms?.map((program) => {
        if (!program.customer || !program.customer.qr_code) return null
        return (
          <Fragment key={program.id}>
            <Button
              width={['50%', 'fit-content']}
              colorScheme="orange"
              leftIcon={<QRCodeIcon size={'16'} />}
              onClick={onOpen}
            >
              {l10n.openQrCode}
            </Button>
            <Modal isOpen={isOpen} onClose={onClose} size={'md'} isCentered>
              <ModalOverlay />
              <ModalContent rounded="md">
                <ModalCloseButton />
                <ModalBody>
                  <Flex flexDirection="column" alignItems="center" justifyContent="center" py={8}>
                    <Image
                      alt=""
                      p={4}
                      width={['95%', 'auto', 'auto', '95%']}
                      src={program.customer.qr_code}
                    />
                    <Flex
                      backgroundColor="blue.100"
                      rounded="sm"
                      p={4}
                      alignItems="center"
                      width="85%"
                    >
                      <Avatar mr={3} size="sm" src={data?.user?.picture_url} />
                      <Text color="blue.900">
                        {data?.user?.name}
                        <Text
                          as="span"
                          display="block"
                          textTransform="capitalize"
                          color="blue.900"
                          fontWeight="normal"
                        >
                          {data?.user?.memberships?.map((m) => m.primary_affiliation)}
                        </Text>
                      </Text>
                    </Flex>
                    <Text mt={4} textAlign="center">
                      {l10n.qrCodeText}
                    </Text>
                    <VerifyAlert />
                  </Flex>
                </ModalBody>
              </ModalContent>
            </Modal>
          </Fragment>
        )
      })}
    </>
  )
}

type Props = {
  // dismissable?: boolean
}

export default QrCode
