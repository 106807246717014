import React from 'react'
import { Box, Text } from '@chakra-ui/react'
import GridContainer from 'components/GridContainer'
import GridItem from 'components/GridItem'
import RichText from 'components/RichText'
import Date from 'components/Date'
import { Alert } from 'types'

const GlobalAlerts = ({ alerts }: Props) => {
  if (!alerts.length) return <></>

  return (
    <Box backgroundColor="red.100" py={[6, 10]}>
      <GridContainer rowGap={4} alignItems="flex-start" mt={[16]}>
        {alerts.map((alert) => (
          <GridItem
            key={alert.uid}
            colStart={1}
            colSpan={alerts.length > 1 ? [12, 6, 4] : [12, 12, 12, 12, 8]}
            p={4}
            backgroundColor="white"
            borderRadius={'md'}
          >
            <Text fontWeight="medium" fontSize={['md', 'lg']}>
              {alert.heading}
            </Text>
            {alert.text && <RichText>{alert.text}</RichText>}
            <Text fontSize="xs" color="gray.600">
              Oppdatert <Date>{alert.updatedAt}</Date>
            </Text>
          </GridItem>
        ))}
      </GridContainer>
    </Box>
  )
}

type Props = {
  alerts: Alert[]
}

export default GlobalAlerts
