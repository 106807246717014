import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  Image,
  Link,
  Stack,
  Text,
  useDisclosure,
  Spinner,
} from '@chakra-ui/react'
import RouterLink from 'next/link'
import { useEffect, useRef } from 'react'
import { FiMenu, FiX } from 'react-icons/fi'
import { MenuItems as MenuItemsType, Service } from 'types'
import { useAuth0 } from '@auth0/auth0-react'
import useLocalisedUri from 'hooks/useLocalisedUri'
import l10n from 'l10n'
import { ArrowRight, ArrowUpRight, ChevronRight, Search, User } from 'lucide-react'
import { useRouter } from 'next/router'
import { useWindowScroll } from 'react-use'
import { FlagEnglish, FlagNorwegian } from './Footer'
import QrCode from './QrCode'

const Header = ({ services, menuItems }: Props) => {
  const { y } = useWindowScroll()
  const { user, logout } = useAuth0()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef()
  const closeBtn = useRef()
  const { data, error, isLoading, localisedUrlNo, localisedUrlEn } = useLocalisedUri()
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = () => {
      if (isOpen) {
        onClose()
      }
    }

    router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <Box
      transition={'all 0.15s cubic-bezier(0.32, 0.72, 0, 1)'}
      // transition={'all 0.15s ease-in-out'}
      m={y > 17 ? 0 : 3}
      borderRadius={y > 17 ? 'none' : 'xl'}
      backgroundColor={'rgba(255,255,255,0.92)'}
      boxShadow={y > 17 ? 'none' : '0px 2px 8px rgba(0, 0, 0, 0.04)'}
      backdropFilter="blur(5px)"
      position="fixed"
      top={0}
      width={
        y > 17
          ? 'full'
          : ['calc(100% - var(--chakra-space-6))', 'calc(100% - var(--chakra-space-8))']
      }
      zIndex={1300}
      borderTop={y > 17 ? 'none' : '1px solid'}
      borderRight={y > 17 ? 'none' : '1px solid'}
      borderBottom={y > 17 ? '1px solid' : '1px solid'}
      borderLeft={y > 17 ? 'none' : '1px solid'}
      borderColor={'gray.200'}
    >
      <Flex
        margin="auto"
        px={[3, 6]}
        py={y > 17 ? 3 : 4}
        as="header"
        alignItems="center"
        justifyContent="space-between"
        color={'gray.800'}
      >
        <RouterLink href="/">
          <Box as="img" src="/logos/mobile.svg" alt="" display={['block', 'none']} />
          <Box as="img" src="/logos/desktop.svg" alt="" display={['none', 'block']} />
        </RouterLink>
        <Stack isInline spacing={[3, 6]} alignItems="center">
          <RouterLink href="/sok" passHref legacyBehavior>
            <Button
              variant="link"
              colorScheme={'dark'}
              as="a"
              size={'sm'}
              leftIcon={<Search size={14} />}
            >
              {l10n.search}
            </Button>
          </RouterLink>
          {!user && (
            <RouterLink href="/minside" passHref legacyBehavior>
              <Button
                variant="link"
                colorScheme={'dark'}
                as="a"
                size="sm"
                leftIcon={<User size={14} />}
              >
                {l10n.myPage}
              </Button>
            </RouterLink>
          )}
          <Button onClick={onOpen} leftIcon={<FiMenu />} ref={btnRef}>
            {l10n.menu}
          </Button>
          {user && (
            <RouterLink href="/minside" passHref>
              <Avatar width="32px" height="32px" src={user?.picture} name={user?.name}></Avatar>
            </RouterLink>
          )}
        </Stack>

        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={btnRef}
          initialFocusRef={closeBtn}
          size="sm"
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerBody as={Flex} flexDirection="column" overflowY="scroll" px={[3, 6]} py={4}>
              <Flex width="100%" alignItems="center" justifyContent="space-between">
                <RouterLink href="/">
                  <Box as="img" src="/logos/mobile.svg" alt="" />
                </RouterLink>
                <Flex gap={4}>
                  <Button
                    variant="outline"
                    colorScheme={'dark'}
                    onClick={onClose}
                    leftIcon={<FiX />}
                    ref={closeBtn}
                  >
                    {l10n.close}
                  </Button>
                </Flex>
              </Flex>
              {/* Spacer */}
              <Box height={8} flex="none"></Box>
              {user ? (
                <Flex gap={2} mb={8}>
                  <QrCode />
                  <RouterLink href={'/minside'} passHref legacyBehavior>
                    <Button
                      width={['50%', 'fit-content']}
                      colorScheme={'dark'}
                      as="a"
                      rightIcon={<ArrowRight size={16} />}
                    >
                      {l10n.goToMyPage}
                    </Button>
                  </RouterLink>
                </Flex>
              ) : (
                <Flex gap={2} mb={8}>
                  <RouterLink href={'/minside'} passHref legacyBehavior>
                    <Button
                      width={['50%', 'fit-content']}
                      as="a"
                      rightIcon={<ArrowRight size={16} />}
                    >
                      {l10n.goToMyPage}
                    </Button>
                  </RouterLink>
                </Flex>
              )}
              <Flex gap={[4, 5]} flexDirection="column">
                <Accordion allowMultiple>
                  {services?.map((service) => (
                    <AccordionItem key={service.uid}>
                      {service?.children?.length > 0 ? (
                        <AccordionButton
                          py={4}
                          px={0}
                          justifyContent="space-between"
                          _expanded={{ bg: 'white' }}
                          _hover={{ bg: 'white', textDecoration: 'underline' }}
                        >
                          <Flex alignItems="center" gap={3}>
                            <Image
                              alt=""
                              src={service.navigationalSymbol?.[0]?.url}
                              width={[4]}
                              height={[4]}
                              display="inline-block"
                            />
                            <RouterLink href={`/${service.uri}`} passHref legacyBehavior>
                              <Link fontWeight="medium" fontSize={'lg'}>
                                {service.title}
                              </Link>
                            </RouterLink>
                          </Flex>
                          <AccordionIcon color={'orange.500'} />
                        </AccordionButton>
                      ) : (
                        <RouterLink href={`/${service.uri}`} passHref legacyBehavior>
                          <Flex
                            as="a"
                            justifyContent={'space-between'}
                            alignItems="center"
                            py={4}
                            _hover={{ textDecoration: 'underline' }}
                          >
                            <Flex alignItems="center" gap={3}>
                              <Image
                                alt=""
                                src={service.navigationalSymbol?.[0]?.url}
                                width={[4]}
                                height={[4]}
                                display="inline-block"
                              />
                              <Text fontWeight="medium" fontSize={'lg'}>
                                {service.title}
                              </Text>
                            </Flex>
                            <Icon as={ChevronRight} color={'orange.500'} />
                          </Flex>
                        </RouterLink>
                      )}

                      {service?.children?.length > 0 && (
                        <AccordionPanel pl={6}>
                          <Flex gap={3} flexDirection="column">
                            {service?.callToActions?.map((cta) => {
                              if (!cta.ctaLink?.available) return null
                              if (cta.ctaLink?.typeHandle === 'entry') {
                                return (
                                  <RouterLink
                                    key={cta.uid}
                                    href={cta?.ctaLink?.url}
                                    passHref
                                    legacyBehavior
                                  >
                                    <Link fontWeight={'medium'}>{cta?.ctaLink?.text}</Link>
                                  </RouterLink>
                                )
                              }
                              if (cta.ctaLink?.typeHandle === 'url') {
                                return (
                                  <Link
                                    key={cta.uid}
                                    href={cta?.ctaLink?.url}
                                    target={cta?.ctaLink?.target}
                                    fontWeight={'medium'}
                                  >
                                    {cta?.ctaLink?.text}
                                  </Link>
                                )
                              }
                              return null
                            })}
                            {service?.children?.map((child) => (
                              <RouterLink
                                key={child.uid}
                                href={`/${child.uri}`}
                                passHref
                                legacyBehavior
                              >
                                <Link>{child.title}</Link>
                              </RouterLink>
                            ))}
                          </Flex>
                        </AccordionPanel>
                      )}
                    </AccordionItem>
                  ))}
                </Accordion>
              </Flex>
              <Flex gap={[3, 4]} flexDirection="column" mt={[8, 10]}>
                {menuItems?.map((item) => (
                  <RouterLink key={item.uid} href={item.generalLink?.url} passHref legacyBehavior>
                    <Link
                      fontWeight={'medium'}
                      target={item.generalLink?.target}
                      isExternal={item.generalLink?.target === '_blank'}
                    >
                      {item.generalLink?.text}{' '}
                      {item.generalLink?.target === '_blank' && (
                        <Icon as={ArrowUpRight} position="relative" top={'3px'} />
                      )}
                    </Link>
                  </RouterLink>
                ))}
                {user && (
                  <Link
                    display={'inline'}
                    textAlign="left"
                    as={'button'}
                    fontWeight={'medium'}
                    onClick={() =>
                      logout({
                        returnTo: process.env.NEXT_PUBLIC_APP_AUTH0_LOGOUT_URI,
                      })
                    }
                  >
                    {l10n.logout}
                  </Link>
                )}
              </Flex>
              {!isLoading ? (
                <Flex flexDirection={'column'} gap={1} mt={[8, 10]}>
                  <RouterLink href={`/${localisedUrlNo}`} passHref locale="nb-NO" legacyBehavior>
                    <Link>
                      <Flex alignItems="center">
                        <FlagNorwegian />
                        Norsk
                      </Flex>
                    </Link>
                  </RouterLink>
                  <RouterLink href={`/${localisedUrlEn}`} passHref locale="en" legacyBehavior>
                    <Link>
                      <Flex alignItems="center">
                        <FlagEnglish />
                        English
                      </Flex>
                    </Link>
                  </RouterLink>
                </Flex>
              ) : (
                <Spinner size={'sm'} />
              )}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Flex>
    </Box>
  )
}

type Props = {
  uid: string
  headerBackgroundColor: string
  colorTheme: string
  services?: Service[]
  submenuItems?: [MenuItemsType]
  menuItems: [MenuItemsType]
}
export default Header
