import { gql } from 'graphql-request'
import { useRouter } from 'next/router'
import useSWR from 'swr'
import { fetcher } from '../utils/cmsDataFetcher'

const GET_ENTRIES = gql`
  query getEntries($uid: [String], $site: [String]) {
    entries(uid: $uid, site: $site) {
      uri
      language
    }
    solspace_calendar {
      events(uid: $uid, site: $site, loadOccurrences: 1, rangeStart: "now") {
        uri
        siteId
      }
    }
  }
`

const GET_UID = gql`
  query getUid($uri: [String], $site: [String]) {
    entry(uri: $uri, site: $site) {
      uid
    }
    solspace_calendar {
      event(uri: $uri, site: $site) {
        uid
      }
    }
  }
`

const useLocalisedUri = () => {
  const router = useRouter()
  const uri = router.asPath.substring(1)?.split('?')?.[0]

  const { data: entryUid } = useSWR([GET_UID, router.asPath], ([query, path]) =>
    fetcher(query, null, {
      uri: decodeURI(uri),
      site: ['english', 'norwegian'],
    })
  )

  const { data, error } = useSWR(entryUid ? [GET_ENTRIES, router.asPath] : null, ([query]) =>
    fetcher(query, null, {
      uid: entryUid.entry ? entryUid.entry.uid : entryUid.solspace_calendar.event.uid,
      site: ['english', 'norwegian'],
    })
  )

  const isLoading = !data && !error

  const fallbackUrl = uri.length > 0 ? uri : ''

  const localisedUrlNo =
    data?.entries[0]?.uri || data?.solspace_calendar?.events[0]?.uri || fallbackUrl
  const localisedUrlEn =
    data?.entries[1]?.uri || data?.solspace_calendar?.events[1]?.uri || fallbackUrl

  return { data, error, isLoading, localisedUrlNo, localisedUrlEn }
}

export default useLocalisedUri
