enum Sites {
  English = 'english',
  Norwegian = 'norwegian',
}

enum ContentSections {
  NewsNo = 'aktuelt',
  NewsEn = 'news',
  StudentUnions = 'studentUnions',
  Offers = 'offers', // New channel
}

enum SinglesNo {
  StudentUnions = 'studentforeninger',
  Eateries = 'spisesteder',
  News = 'aktuelt',
}

enum ContentCategories {
  Eatery = 'eatery',
  Campus = 'campus',
  Topic = 'topic',
  Service = 'service',
  StudentHouse = 'studentHouse',
  EventTopic = 'eventTopic',
  Faculty = 'faculty',
}

enum EntryTypes {
  Eatery = 'eatery',
  Pages = 'pages',
  List = 'list',
  Event = 'event',
  Events = 'events',
  Marketing = 'marketing',
  Offer = 'offer',
  Offers = 'offers', // New channel
  Promotion = 'promotion',
  Service = 'service',
  NewsList = 'newsList',
  Search = 'search',
  ArticleList = 'articleList',
  Housing = 'housing',
  News = 'news',
  CreateEvent = 'createEvent',
  CreateStudentUnion = 'createStudentUnion',
}

enum BlockTypes {
  RichText = 'richText',
  Callout = 'callout',
  DescriptionList = 'descriptionList',
  SymbolList = 'symbolList',
  Images = 'images',
  Embed = 'embed',
  Downloads = 'downloads',
  Persons = 'persons',
  Map = 'map',
  CallToAction = 'callToAction',
  Accordion = 'accordion',
  InfoBox = 'infoBox',
  Promotional = 'promotional',
  Features = 'features',
  Card = 'card',
  Form = 'form',
  ContactInfo = 'contactInfo',
  Table = 'table',
  BreakingNews = 'breakingNews',
  Articles = 'articles',
  Quote = 'quote',
}

enum Services {
  Health = 'Helse',
  StudentLife = 'Studentliv',
  FoodAndBeverage = 'Mat og drikke',
  Housing = 'Bolig',
  Childcare = 'Barnehager',
  Training = 'Trening',
  UnknownService = 'Ukjent tjeneste',
  Marketing = 'Marketing',
}

enum FavoriteTypes {
  Campuses = 'campuses',
  Restaurants = 'restaurants',
  Persons = 'persons',
  Homes = 'homes',
  TrainingStudios = 'training_studios',
  Diets = 'diets',
}

enum EateryFeatures {
  Unmanned = 'unmanned',
  CardOnly = 'cardOnly',
}

enum Housing {
  Campus = 'campus',
  Area = 'housingArea',
  Type = 'housingType',
  Price = 'price',
  Size = 'size',
  SuitableFor = 'housingSuitableFor',
  Label = 'housingLabel',
}

enum Articles {
  Articles = 'articles',
  ArticleTopic = 'articleTopic',
}

enum HousingAreaShortNames {
  'Tunvegen' = 'tunvg',
  'Stakkevegen' = 'stakkevg',
  'Rødhettestien' = 'rodhs7',
  'Smørbukklia' = 'sml',
  'Nedre Smørbukklia' = 'nsml',
  'Luleåvegen' = 'luleavg',
  'Forhåpningen' = 'fh',
  'Gitta Jønsonsveg' = 'gittaj4',
  'Olastien' = 'olast',
  'Ørnevegen' = 'ornevg',
  'Borgensvingen' = 'borgsv',
  'Åsgårdvegen' = 'asgvg',
  'Follumsvei' = 'follumsvn',
  'Komsahøyden' = 'komsah',
  'Tollevikveien' = 'tollevn',
  'Ørneveien' = 'ornevn',
  'Sykehusveien' = 'hfestint',
  'Skistuaveien' = 'skivn',
  'Fjellveien' = 'fjvn33',
  'Bregnetunet' = 'bregne',
  'Fiolstien' = 'fiols',
  'Sildretunet' = 'sil',
  'Flåtenveien' = 'flatenvn',
  'Storvollen' = 'storv',
  'Magnus gate' = 'torden',
  'Rådyrveien' = 'raadyrvn',
  'Luftslottveien' = 'luftslott',
  'Universitetsvegen' = 'univg',
}

export default {
  HousingAreaShortNames,
  Sites,
  ContentSections,
  ContentCategories,
  BlockTypes,
  Services,
  SinglesNo,
  FavoriteTypes,
  EntryTypes,
  EateryFeatures,
  Housing,
  Articles,
}
