import { useAuth0 } from '@auth0/auth0-react'
import { GraphQLClient, request } from 'graphql-request'
import { useEffect, useState } from 'react'

export const API = process.env.NEXT_PUBLIC_APP_NASDB_URL

export const fetcher = (query, ...args) => {
  return request(API, query, ...args)
}

export const useFetchWithToken = () => {
  const { getIdTokenClaims } = useAuth0()
  const [token, setToken] = useState(null)
  const [fetcher, setFetcher] = useState(null)
  const [refreshIndex, setRefreshIndex] = useState(0)

  useEffect(() => {
    ;(async () => {
      try {
        const claims = await getIdTokenClaims()
        const graphQLClient = new GraphQLClient(API, {
          headers: {
            Authorization: `Bearer ${claims?.__raw}`,
          },
        })
        setFetcher(graphQLClient)
      } catch (e) {
        console.error(e)
      }
    })()
  }, [refreshIndex])

  return { fetcher, token }
}
