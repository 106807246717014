import { Box } from '@chakra-ui/react'
import ChatBot from 'components/ChatBot'
import parse from 'html-react-parser'
import Head from 'next/head'
import React from 'react'
import { MenuItems, ContactInfo } from 'types'
import Footer from '../components/Footer'
import GlobalAlerts from '../components/GlobalAlerts'
import Header from '../components/Header'

const BaseLayout = ({
  title,
  children,
  headerBackgroundColor,
  colorTheme,
  metaTitleContainer = 'Samskipnaden.no',
  metaTagContainer = '',
  metaLinkContainer = '',
  footerMenu,
  mainMenu,
  alerts,
  contactInfo,
}: Props) => {
  return (
    <>
      <Head>
        {title && <title>{title}</title>}
        {parse(metaTitleContainer)}
        {parse(metaTagContainer)}
        {parse(metaLinkContainer)}
      </Head>
      <Header colorTheme={colorTheme} headerBackgroundColor={'white'} {...mainMenu} />
      {alerts && <GlobalAlerts {...alerts} />}
      {children}
      {/* <ChatBot /> */}
      <Footer {...footerMenu} contactInfo={contactInfo} />
    </>
  )
}

type Props = {
  title: string
  children: React.ReactNode
  headerBackgroundColor?: string
  colorTheme?: string
  mainMenu?: {
    uid: string
    menuItems: [MenuItems]
    submenuItems?: [MenuItems]
  }
  footerMenu?: {
    uid: string
    menuItems: [MenuItems]
  }
  alerts?: any
  contactInfo?: ContactInfo
  metaTitleContainer?: string
  metaTagContainer?: string
  metaLinkContainer?: string
}

export default BaseLayout
