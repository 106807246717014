import { request } from 'graphql-request'

export const API = process.env.NEXT_PUBLIC_APP_CMS_URL

export const fetcher = (query, qs, ...args) => {
  let endpoint = API
  if (qs) {
    const isPreview = ((qs['x-craft-live-preview'] || qs['x-craft-preview']) && qs.token) || false

    endpoint = isPreview ? `${API}?token=${qs.token}` : API
  }

  return request(endpoint, query, ...args)
}
